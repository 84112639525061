<template>
  <div>
    <div class="ContentHeader">
      <div class="HeaderAll">
        <slot
          name="titleBreadcrumb"
          :messageProps="'Movement ' + $route.query.tipe"
        ></slot>
        <a-breadcrumb>
          <a-breadcrumb-item
            ><router-link :to="{ name: 'Home' }">Home</router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-for="(item, i) in breadcrumb" :key="i">
            <router-link
              :to="{ name: item.nameroute }"
              v-if="i + 1 < breadcrumb.length"
              >{{ item.name }}</router-link
            >
            <span v-else>{{ item.name }}</span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <div class="allcard" style="height: calc(100vh - (262px))">
      <simplebar class="simplebarcustom" data-simplebar-auto-hide="false">
        <div style="margin-top: -30px">
          <div v-if="$route.query.tipe == 'Perbulan'">
            <a-row :gutter="[15, 15]">
              <a-col :lg="18">
                <div
                  class="dashboarddmtop detaildashboarddm"
                  style="overflow: hidden"
                >
                  <a-spin :spinning="loading4">
                    <Bar
                      :chart-data="dataChart"
                      :chart-options="options"
                      :height="230"
                    />
                  </a-spin>
                </div>
              </a-col>
              <a-col :lg="6">
                <div class="dashboarddmbottom detaildashboarddm">
                  <img :src="require('@/assets/img/icon/deleted.svg')" />
                  <h3>
                    <a-spin :spinning="loading4">{{ today.deleted }}</a-spin>
                  </h3>
                  <div>Deleted</div>
                </div>
                <div class="dashboarddmbottom detaildashboarddm">
                  <img :src="require('@/assets/img/icon/addition.svg')" />
                  <h3>
                    <a-spin :spinning="loading4">{{ today.addition }} </a-spin>
                  </h3>
                  <div>Addition</div>
                </div>
              </a-col>
            </a-row>
          </div>
          <div v-if="$route.query.tipe == 'Deleted'">
            <div class="dashboarddmbottom detaildashboarddm">
              <img :src="require('@/assets/img/icon/deleted.svg')" />
              <h3>
                <a-spin :spinning="loading4">{{ today.deleted }}</a-spin>
              </h3>
              <div>Deleted</div>
            </div>
          </div>

          <div v-if="$route.query.tipe == 'Addition'">
            <div class="dashboarddmbottom detaildashboarddm">
              <img :src="require('@/assets/img/icon/addition.svg')" />
              <h3>
                <a-spin :spinning="loading4">{{ today.addition }} </a-spin>
              </h3>
              <div>Addition</div>
            </div>
          </div>
        </div>

        <a-table
          :rowKey="(record) => record.id"
          :columns="columns"
          :dataSource="data"
          :pagination="false"
          :scroll="{ x: widthtable }"
          :loading="loading"
          @change="handleTableChange"
        >
          <div slot="filterDropdown" slot-scope="{ column }">
            <div class="wrapbuttonsort">
              <a-button
                block
                type="link"
                @click="
                  handlesTbl('nama_peserta', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'nama_peserta'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    Nama Peserta
                  </div>
                </div>
              </a-button>

              <a-button
                block
                type="link"
                @click="
                  handlesTbl('tanggal_lahir', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'tanggal_lahir'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    Tgl Lahir
                  </div>
                </div>
              </a-button>

              <a-button
                type="link"
                @click="
                  handlesTbl('jenis_kelamin', [undefined, 'ascend', 'descend'])
                "
              >
                <div class="customsortdown">
                  <div
                    :style="
                      column.choseSort == 'jenis_kelamin'
                        ? 'color:#0060D1'
                        : 'color:#77849D'
                    "
                  >
                    Jenis Kelamin
                  </div>
                </div>
              </a-button>
            </div>
          </div>

          <div
            slot="filterIcon"
            slot-scope="filtered, column"
            class="customSorter"
          >
            <a-icon
              type="caret-up"
              :style="{
                color: column.customSort == 'ascend' ? '#0060D1' : '#77849D',
              }"
            />

            <a-icon
              type="caret-down"
              :style="{
                color: column.customSort == 'descend' ? '#0060D1' : '#77849D',
              }"
            />
          </div>

          <template slot="nama_pasien" slot-scope="text, record">
            <div class="iconnamapasien">
              <div>
                <router-link
                  :to="{
                    name: 'DetailPeserta',
                    params: { id: record.id_job_order, idpeserta: record.id },
                    query: { status: $route.query.status },
                  }"
                  >{{ record.nama_peserta }} <a-icon type="right"
                /></router-link>
              </div>
              <span class="iconcalendarblack"
                ><img :src="require('@/assets/img/icon/dateblack.svg')" />{{
                  record.tanggal_lahir
                }}</span
              >
              <span class="iconcalendar"
                ><img
                  :src="
                    require(`@/assets/img/icon/${record.jenis_kelamin}.svg`)
                  "
                />
                {{ record.jenis_kelamin }}</span
              >
            </div>
          </template>
          <template slot="nama_karyawan" slot-scope="text, record">
            <router-link
              :to="{
                name: 'DetailPeserta',
                params: {
                  id: record.id_job_order,
                  idpeserta: record.id_karyawan_self,
                },
                query: { status: $route.query.status },
              }"
              >{{ text }} <a-icon type="right"
            /></router-link>
          </template>
          <template slot="nik" slot-scope="text, record">
            <div>{{ text }}</div>
            <div>{{ record.unique_id }}</div>
          </template>

          <template slot="plan" slot-scope="text">
            <div class="iconpeserta">
              <img :src="require('@/assets/img/icon/ri.svg')" />RI - {{ text }}
            </div>
          </template>

          <template slot="no_peserta" slot-scope="text, record">
            <div>{{ text }}</div>
            <div>{{ record.no_kartu }}</div>
          </template>

          <template slot="status_movement" slot-scope="text">
            <span
              :style="text == 'DELETE' ? 'color:#FF4743' : 'color:#00E077'"
              >{{ text }}</span
            >
          </template>

          <template slot="action" slot-scope="text, record">
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                <span class="iconmmore">
                  <a-icon type="more" />
                </span>
              </a>
              <div slot="overlay" class="overlaymenumore">
                <div class="wrapoverlaymore">
                  <div class="submenumore">
                    <div>
                      <router-link
                        :to="{
                          name: 'DetailPeserta',
                          params: {
                            id: $route.params.id,
                            idpeserta: record.id,
                          },
                        }"
                        >View <img :src="require('@/assets/img/icon/eye.svg')"
                      /></router-link>
                    </div>
                    <div v-if="$route.query.status == undefined">
                      <router-link
                        :to="{
                          name: 'EditPeserta',
                          params: {
                            id: $route.params.id,
                            idpeserta: record.id,
                          },
                        }"
                        >Edit <img :src="require('@/assets/img/icon/edit.svg')"
                      /></router-link>
                    </div>
                    <div v-if="$route.query.status == undefined">
                      <router-link
                        to="#"
                        @click.native="
                          modal(
                            'Delete',
                            record.id,
                            record.nama_peserta,
                            record.id_job_order_perusahaan
                          )
                        "
                        >Delete
                        <img :src="require('@/assets/img/icon/delete.svg')"
                      /></router-link>
                    </div>
                    <div v-if="$route.query.status == 'nonaktif'">
                      <router-link
                        :to="{ query: { status: 'nonaktif' } }"
                        @click.native="
                          modal(
                            'Reaktif',
                            record.id,
                            record.nama_peserta,
                            record.id_job_order_perusahaan
                          )
                        "
                        >Reaktif
                        <img :src="require('@/assets/img/icon/reaktif.svg')"
                      /></router-link>
                    </div>
                    <div v-if="$route.query.status == undefined">
                      <router-link
                        to="#"
                        @click.native="
                          modal(
                            'Reprint',
                            record.id,
                            record.nama_peserta,
                            record.id_job_order_perusahaan
                          )
                        "
                        >Reprint
                        <img :src="require('@/assets/img/icon/reprint.svg')"
                      /></router-link>
                    </div>
                    <div v-if="$route.query.status == undefined">
                      <router-link
                        to="#"
                        @click.native="
                          modal(
                            'Mutasi',
                            record.id,
                            record.nama_peserta,
                            record.id_job_order_perusahaan
                          )
                        "
                        >Mutasi
                        <img :src="require('@/assets/img/icon/mutasi.svg')"
                      /></router-link>
                    </div>
                  </div>
                </div>
              </div>
            </a-dropdown>
          </template>
          <template slot="footer">
            {{ null }}
          </template>
        </a-table>
        <div class="paginationcustom" style="margin-top: 0px !important">
          <a-pagination
            showSizeChanger
            @showSizeChange="onShowSizeChange"
            :pageSizeOptions="pageSizeOption"
            :pageSize.sync="pageSize"
            :total="total"
            v-model="page"
            :showTotal="
              (total, range) =>
                ` ${range[0]}-${range[1]}  of ${
                  pagination.total == undefined ? '0' : pagination.total
                } `
            "
            @change="handlePageChange"
          >
            <template slot="buildOptionText" slot-scope="props">
              <span v-if="props.value !== '1000000'">
                {{ props.value }} / page
              </span>
              <span v-if="props.value == '1000000'">All</span>
            </template>
          </a-pagination>
        </div>
      </simplebar>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
const columns = [
  {
    title: "Nama peserta, Tgl Lahir, Jenis Kelamin",
    dataIndex: "nama_pasien",
    scopedSlots: {
      customRender: "nama_pasien",
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
    },
    customSort: "",
    choseSort: "",
    width: 300,
  },
  {
    title: "Nama Karyawan",
    dataIndex: "nama_karyawan",
    scopedSlots: { customRender: "nama_karyawan" },
    sorter: true,
    width: 200,
  },
  {
    title: "NIK & Unique ID",
    dataIndex: "nik",
    scopedSlots: { customRender: "nik" },
    sorter: true,
    width: 200,
  },
  {
    title: "No.Peserta & No.Kartu",
    dataIndex: "no_peserta",
    scopedSlots: { customRender: "no_peserta" },
    sorter: true,
    width: 200,
  },
  {
    title: "Status",
    dataIndex: "status_peserta",
    scopedSlots: { customRender: "status" },
    sorter: true,
    align: "center",
    width: 200,
  },
  {
    title: "Plan RI",
    dataIndex: "ri",
    scopedSlots: { customRender: "plan" },
    sorter: true,
    width: 200,
  },
  {
    title: "Tgl Efektif",
    dataIndex: "tgl_efektif",
    sorter: true,
    width: 150,
  },
  {
    title: "Status Movement",
    dataIndex: "status_movement",
    sorter: true,
    scopedSlots: { customRender: "status_movement" },
    align: "center",
    fixed: "right",
    width: 150,
  },
];

const options = {
  borderRadius: 30,
  aspectRatio: 2,
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      display: true,

      ticks: {
        display: false,
        beginAtZero: true,
      },
      grid: {
        drawBorder: false,
        color: (context) => {
          if (context.tick.value === 0) {
            return "#D8DDEC";
          }
        },
        tickLength: 0,
      },
    },

    x: {
      display: true,
      ticks: {
        display: true,
        beginAtZero: true,
      },

      grid: {
        tickLength: 1,
        color: "rgb(216 221 236)",
        drawOnChartArea: false,

        drawTicks: true,
      },
    },
  },
};
export default {
  components: {
    simplebar,
    Bar,
  },
  data() {
    return {
      data: [],
      pageSizeOption: ["15", "30", "60", "1000000"],
      pagination: {},
      total: 0,
      page: 1,
      current: 1,
      pageSize: 15,
      loading: false,
      loading4: false,
      columns: columns,
      heighttable: 0,
      options,
      model: [],
      dataChart: {},
      today: 0,
      click: 0,
      top: 0,
      labels: "",
      widthtable: 0,
      breadcrumb: [
        {
          name: "Movement " + this.$route.query.tipe,
          nameroute: "",
          param: {},
        },
      ],
    };
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.fetch({
      page: 1,
      pageSizes: 15,
      group: "-",
      id_perusahaan: this.$route.query.id_job_order_perusahaan,
    });
  },
  methods: {
    handlesTbl(item, sortby) {
      if (this.labels == "") {
        this.labels = item;
      }

      if (this.labels != item) {
        this.labels = item;
        this.click = 0;
      }

      console.log("labels", this.labels);
      this.click += 1;

      if (this.click == 3) {
        this.click = 0;
      }

      let sorting = {
        field: item,
        order: sortby[this.click],
      };
      this.handleTableChange(this.page, "", sorting);
      if (
        item == "nama_peserta" ||
        item == "tanggal_lahir" ||
        item == "jenis_kelamin"
      ) {
        this.columns[0].customSort = sortby[this.click];
        this.columns[0].choseSort = item;
      }

      //console.log(this.columns[1].choseSort);
      // if (item == "tanggal_keluar" || item == "tanggal_masuk") {
      //   this.columns[5].customSort = sortby[this.click];
      //   this.columns[5].choseSort = item;
      // }

      //set columnya ke sorter

      //console.log(this.columns[1].choseSort);
    },
    handleTableChange(page, filter, sorter) {
      if (
        sorter.field != "nama_peserta" ||
        sorter.field != "tanggal_lahir" ||
        sorter.field != "jenis_kelamin"
      ) {
        this.columns[1].customSort = "";
      }
      const pager = { ...this.pagination };

      pager.current = page;
      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        group: "-",
        id_perusahaan: this.$route.query.id_job_order_perusahaan,
        sortField: sorter.field,
        sortOrder: sorter.order,
        search: this.search,
      });
    },
    onShowSizeChange(current, pageSize) {
      this.page = current;
      this.pageSize = pageSize;
      this.fetch({
        page: this.page,
        pageSizes: this.pageSize,
        group: "-",
        id_perusahaan: this.$route.query.id_job_order_perusahaan,
        search: this.search,
      });
      this.handlePageChange(current);
    },
    handlePageChange(page) {
      const params = this.paramssearch;
      const pager = { ...this.pagination };
      pager.current = page;

      this.fetch({
        page: pager.current,
        pageSizes: pager.pageSize,
        group: "-",
        id_perusahaan: this.$route.query.id_job_order_perusahaan,
      });
    },
    handleResize() {
      // this.heighttable = screen.height - 805;
      this.heighttable = screen.height;
      this.widthtable = screen.width - 2006;
    },
    fetch(params = {}) {
      if (this.$route.query.tipe == "Perbulan") {
        this.loading = true;
        this.loading4 = true;
        this.$store
          .dispatch("getPesertaDetailDashboard", {
            ...params,
          })
          .then((response) => {
            this.loading = false;
            //   this.today = response.data.data.today;
            this.today = {
              deleted: response.data.data.filter(
                (key) => key.status_movement == "DELETE"
              ).length,
              addition: response.data.data.filter(
                (key) => key.status_movement == "ADD"
              ).length,
            };
            const pagination = { ...this.pagination };
            pagination.total = response.data.total;
            pagination.pageSize = response.data.pageSize;
            pagination.page = response.data.page;

            this.total = pagination.total;
            this.data = response.data.data;
            this.pagination = pagination;
            this.model = [];
          });

        this.$store
          .dispatch("getPeserta", {
            group: this.$store.state.Auth.data.group[0],
            id_perusahaan: this.$route.query.id_job_order_perusahaan,
          })
          .then((response) => {
            this.loading4 = false;

            this.dataChart = {
              labels: response.data.data.month.map(
                (key) => Object.keys(key)[0]
              ),
              datasets: [
                {
                  label: "Additon",
                  backgroundColor: "#80e1b6",
                  data: response.data.data.month.map(
                    (key) => Object.values(key)[0].addition
                  ),
                  stack: "stack 1",

                  barPercentage: 0.4,
                },

                {
                  label: "Delete",
                  backgroundColor: "#ef8886",
                  data: response.data.data.month.map(
                    (key) => Object.values(key)[0].delete * -1
                  ),
                  stack: "stack 1",
                  barPercentage: 0.4,
                },
              ],
            };

            // console.log(
            //   response.data.data.month.map(
            //     (key) => Object.values(key)[0].addition
            //   )
            // );
          })
          .catch((error) => {
            this.loading4 = false;
          });
      }

      if (
        this.$route.query.tipe == "Addition" ||
        this.$route.query.tipe == "Deleted"
      ) {
        let tipe = this.$route.query.tipe == "Addition" ? "ADD" : "DELETE";
        this.$store
          .dispatch("getPesertaPermovement", {
            ...params,
            tipe: tipe,
          })
          .then((response) => {
            this.loading = false;
            //   this.today = response.data.data.today;
            this.today = {
              deleted: response.data.data.filter(
                (key) => key.status_movement == "DELETE"
              ).length,
              addition: response.data.data.filter(
                (key) => key.status_movement == "ADD"
              ).length,
            };
            const pagination = { ...this.pagination };
            pagination.total = response.data.total;
            pagination.pageSize = response.data.pageSize;
            pagination.page = response.data.page;

            this.total = pagination.total;
            this.data = response.data.data;
            this.pagination = pagination;
            this.model = [];
          });
      }
    },
  },
};
</script>
